import axios from "axios";
// 引入进度条
import store from "@/store";
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
const requests = axios.create({
    //配置对象
    //基础路径，发请求的时候，路径当中会出现api
    baseURL: 'https://admin.zhzyd.com/api',
    timeout: 5000
})
//请求拦截器: 在发请求之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
requests.interceptors.request.use((config) => {
    nprogress.start()
    return config
})

//相应拦截器
requests.interceptors.response.use((res) => { 
     //要引入User仓库
     nprogress.done()
    return res.data
}, (error) => {
    console.log("🚀 ~ requests.interceptors.response.use ~ error:", error)
    return Promise.reject(new Error('fail'))
})

export default requests